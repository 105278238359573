<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Habitación - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Tipo de Habitacion :" required>
                    <select ref="room"
                      class="form-control"
                      disabled
                      v-model="room.id_type_room" >
                      <option value="">-- Seleccione --</option>
                      <option v-for="item of type_room" :key="item.id_type_room" :value="item.id_type_room">
                        {{ item.name }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Nro Habitación:">
                    <b-form-input
                      type="text"
                      disabled
                      ref="room_number"
                      v-model="room.room_number"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Precio:">
                    <b-form-input
                      type="number" 
                      step="any"
                      class="text-right"
                      ref="price"
                      disabled
                      v-model="room.price"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Foto:">
                    <img class="img-fluid" :src="url_base + room.photo" alt="">
                    </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Descripción :" required>
                    <b-form-textarea
                      v-model="room.description"
                      rows="3"
                      disabled
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      disabled
                      v-model="room.state"
                      class="form-control">
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3"></b-col>

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-link
                    class="btn form-control btn-primary"
                    :to="{ path: '/habitacion/listar' }"
                    append
                    >REGRESAR</b-link
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "CategoriaView",
  props: ["id_room"],
  data() {
    return {
      module:'Room',
      room: {
        id_room: "",
        id_type_room: "",
        room_number: "",
        photo: "",
        photo_change:"",
        price:0,
        description: "",
        state: 1,
      },
      type_room:[],
      validate: false,
    };
  },
  mounted() {
    this.ListTypeRoom();
    this.ViewRoom();
  },
  methods: {
    ListTypeRoom,
    ViewRoom,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};



function ListTypeRoom() {
  let me = this;
  let url = this.url_base + "active-type-room";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.type_room = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {});
}

//ver usuario
function ViewRoom() {
  let id_room = je.decrypt(this.id_room);
  let me = this;
  let url = this.url_base + "room/view/" + id_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 5,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.id_type_room = response.data.result.id_type_room;
        me.room.room_number = response.data.result.room_number;
        me.room.photo = response.data.result.photo;
        me.room.price = response.data.result.price;
        me.room.description = response.data.result.description;
        me.room.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {

    });
}

</script>
